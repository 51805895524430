/* General Banner Content */
.banner-content, .banner-content1 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 740px;
  overflow: hidden;
}

.banner-content {
  background-image: url(../../Assests/Img/banner1\ \(1\).webp);
}

.banner-content1 {
  background-image: url(../../Assests/Img/banner-2.webp);
}
/* Head Text Styling */
.head-text {
  position: relative;
  margin: 200px auto 0px auto;
  text-align: center;
}
.head-text h1, .head-text h3{
  margin: 0;
  color: rgb(255, 255, 255);
  font-weight: 700;
}
.head-text h4, .head-text h5 {
  margin: 0;
  color: rgb(72, 196, 212);
  font-weight: 700;
}
.head-text h1, .head-text h3, .head-text h4 {
  font-size: 60px;
}

.head-text h5 {
  font-weight: 300;
  font-size: 60px;
  color: rgb(72, 196, 212);
  padding-right: 190px;
}

/* Info Section */
.info {
  padding-top: 60px;
  display: flex;
  justify-content: center;
  gap: 20px;
}
.info h2, .info h3, .info p {
  color: rgb(255, 255, 255);
}
.info h2 {
  font-size: 18px;
  line-height: 18px;
}

.info h3 {
  font-size: 14px;
  line-height: 18px;
}

.info p {
  font-size: 16px;
  line-height: 25px;
  width: 227px;
}

.sig {
  padding-left: 70px;
}
.head-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20%;
}

.shah {
  border-radius: 50%;
  width: 76px;
  height: 76px;
}


/* Responsive Design */

@media screen and (min-width:1200px) {
  .head,
  .head1 {
    max-width: 1200px;
    margin-inline: auto;
  }
}
@media screen and (max-width: 1024px) {
  .head-text h1, .head-text h4, .head-text h5 {
    font-size: 51px;
  }
}
@media screen and (max-width: 900px) {
  .head-text h1, .head-text h4 {
    font-size: 44px;
  }

  .head-text h5 {
    font-size: 44px;
    padding-right: 90px;
  }
}
@media screen and (max-width: 768px) {
  .banner-content, .banner-content1 {
    height: 400px;
  }
  .banner-content {
    background-image: url(../../Assests/Img/banner_mobile[1].webp);
  }
  
  .banner-content1 {
    background-image: url(../../Assests/Img/banner2425.webp);
  }
  .head-text {
    margin: 120px auto 0 auto;
  }

  .head-text h1, .head-text h3, .head-text h4, .head-text h5 {
    font-size: 30px;
    text-align: center;
    padding-left: 20px;
  }
  .info {
    padding-top: 40px;
  }
  .sig-row{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 690px) {
  .banner-content, .banner-content1 {
    background-size:contain ;
  }
  .banner-content {
    background-image: url(../../Assests/Img/banner_mobile[1].webp);
  }
  
  .banner-content1 {
    background-image: url(../../Assests/Img/banner2425.webp);
  }
  .head-text h1, .head-text h4, .head-text h5,.head-text h3 {
    font-size: 28px;
    padding-left: 60px;
  }
  .info{
    padding-top: 20px;
    padding-left: 30px;
  }
  .sig{
    padding: 0;
  }
}

@media screen and (max-width: 569px) {
  .banner-content, .banner-content1 {
    background-size:contain ;
    height: auto;
    margin: 0;
    padding: 0;
  }
  .banner-content {
    background-image: url(../../Assests/Img/banner_mobile[1].webp);
  }
  
  .banner-content1 {
    background-image: url(../../Assests/Img/banner2425.webp);
  }
  .head-text h1, .head-text h4,.head-text h3 {
    font-size: 26px;
    padding-left: 20px;
  }
  .head-text h5{
    font-size: 26px;
    padding-left: 120px;
  }
  .head-text {
    margin: 90px auto 0 auto;
  }
  .info {
    padding-top: 10px;
    padding-left: 90px;
  }
  .info h2, .info h3, .info p{
    margin: 10px;
  }
  .info h2 {
    font-size: 16px;
  }  
}
@media screen and (max-width: 499px) {
  .banner-content, .banner-content1 {
    height: 350px;
    margin: 0;
    padding: 0;
  }
  
  .head-text h1, .head-text h4, .head-text h3 {
    font-size: 18px;
  }
  .head-text h5{
    font-size: 24px;
    padding-left: 120px;
  }
  .head-text {
    margin: 90px auto 0 auto;
  }
  .info {
    padding-top: 10px;
    padding-left: 120px;
  }
}
@media screen and (max-width: 419px) {
  .banner-content, .banner-content1 {
    position: relative;
    background-size:contain ;
    height: 390px;
    margin: 0;
    padding: 0;
    top: -26px;
  }
  
  .head-text h1, .head-text h4,.head-text h3 {
    font-size: 22px;
  }
  .head-text h5{
    font-size: 22px;
    padding-left: 60px;
    padding-right: 0;
  }
  .head-text {
    margin: 110px auto 0 auto;
  }
  .info {
    padding-top: 10px;
    padding-left: 20px;
  }
  .sig{
    display: none;
  }
}
@media screen and (max-width: 390px){
  .banner-content, .banner-content1 {
    position: relative;
    background-size:contain ;
    top: -30px;
  }
  
  .head-text h1, .head-text h4,.head-text h3 {
    font-size: 20px;
  }
  .head-text h5{
    padding-left: 40px;
    font-size: 20px;
  }
  .info h2, .info h3{
     margin: 5px;
     font-size: smaller;
  }
}
@media screen and (max-width: 375px){
  .banner-content, .banner-content1 {
    position: relative;
    background-size:contain ;
    top: -36px;
  }
  
  .head-text h1, .head-text h4, .head-text h5,.head-text h3 {
    font-size: 18px;
  }
  .info h2, .info h3{
     margin: 5px;
     font-size: smaller;
  }
}
@media screen and (max-width: 360px){
  .banner-content, .banner-content1 {
    position: relative;
    top: -40px;
  }
  .head-text h5{
    padding-left: 30px;
    font-size: 20px;
  }
  .info{
    gap: 5px;
  }
  .info p{
    margin-top: 5px;
  }
  .head-text {
    margin: 125px auto 0 auto;
  }
}
@media screen and (max-width: 338px){
  .banner-content, .banner-content1 {
    position: relative;
    top: -45px;
  }
  .head-text h5{
    padding-left: 30px;
    font-size: 20px;
  }
  .info{
    gap: 5px;
  }
  .info p{
    margin-top: 5px;
    margin-left: 0;
    font-size: smaller;
  }
  .head-text {
    margin: 125px auto 0 auto;
  }
}
@media screen and (max-width: 320px){
  .banner-content, .banner-content1 {
    position: relative;
    top: -46px;
  }
}

 /* 501 in 570 pora641 to 678  705 to 729size error */