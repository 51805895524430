.project-container {
    width: 100%;
    height: auto;
    background-color: #ffffff;
    margin-top: -30px;
}

.project-data h1 {
    color: #20232f;
    line-height: 60px;
    font-size: 36px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    padding-top: 25%;
    max-width: 564px;
    padding-bottom: 20px;

}
 
.project-data p {
    color: #555555;
    font-size: 18px;
    font-weight: 300;
    width: 100%;
    line-height: 1.7;
}

.project button {
    border-radius: 9px;
    border: none;
    background-color: rgb(49 50 65);
    color: rgb(255, 255, 255);
    margin-top: 90px;
    height: 64px;
    padding: 0px 45px;
    margin-left: 0;
    text-decoration: none;
    font-weight: 700;
    font-size: 19px;
    display: inline-block;
    text-transform: uppercase;
    margin-bottom: 50px;
}

.project button:hover {
    background-color: #20232f;
    transition: 2s;
}

.PROJECT {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;

}

.port-box {
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    background: #292e33;
    display: flex;
    grid-template-rows: 1fr auto;
    align-items: center;
    margin-top: 160px;
}

.port-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.port-image img {
    width: 100%;
    opacity: 0.5s;
    height: 100%;
    transition: 0.5s;
}

.port-content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.2), #12f7ff);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    transition: 0.5s;
    padding: 0 -1rem;
}

.port-box:hover .port-content {
    opacity: 1;
}

.port-box:hover .port-image img {
    transform: scale(1.1);
}

.port-content a {
    background: #fff;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border: 1px solid aquamarine;
    outline: 2px solid #fff;
    margin-bottom: 20px;
}

.port-content a i {
    font-size: 1.3rem;
    padding: 10px;
    color: black;
}

/* Media Query */

@media screen and (min-width:1200px) {
    .project-content {
        max-width: 1200px;
        margin-inline: auto;
    }

    .project-data {
        max-width: 1200px;
        margin-inline: auto;
    }
}

@media screen and (max-width:1024px) {
    .project-container {
        width: 100%;
        margin-top: -37px;
    }

    .project-data h1{
        padding-left: 20px;
    }
    .project-data p{
        margin: 0px 20px 0 20px;
        max-width: 984px;
    }
    .project-data button{
        margin-left: 20px;
    }
    .PROJECT{
        display: contents;
    }
    .project{
        padding-left: 20px;
    }
    .port-box{
        margin: 60px 20px 0 20px;
    }

}
@media screen and (max-width:912px) {
    .project-data p {
        margin: 0px 20px 0 20px;
        max-width: 870px;
    }

}
@media screen and (max-width:900px) {
    .project-data h1 {
        font-size: 32px;
    }

    .project-data p {
        color: #555555;
        
        font-weight: 300;
        max-width: 860px;
    }
}
@media screen and (max-width:820px){
    .project-data p {
        color: #9b9c9c;
       
        font-weight: 300;
        max-width: 755px;
    }
}
@media screen and (max-width:800px) {
    .project-data h1 {
        font-size: 28px;
    }

    .project-data p {
        color: #9b9c9c;
        font-size: 18px;
        font-weight: 300;
        max-width: 700px;
        line-height: 1.5;
    }
}

@media screen and (max-width:767px) {
    .project-data h1 {
        font-size: 28px;
        padding-top: 16px;
    }
    .project button {
        text-align: center;
        border-radius: 9px;
        border: none;
        background-color: rgb(49 50 65);
        color: rgb(255, 255, 255);
        margin-top: 29px;
        height: 64px;
        padding: 0px 45px;
        margin-left: 16%;
        text-decoration: none;
        font-weight: 700;
        font-size: 19px;
        display: inline-block;
        text-transform: uppercase;
        margin-bottom: 27px;
        }
    .project-data p {
        color: #555555;
        font-size: 24px;
        font-weight: 300;
        max-width: 725px;
    }
}

@media screen and (max-width:700px) {
    .project-data h1 {
        font-size: 24px;
    }
    .project-data p {
        color: #9b9c9c;
        font-size: 24px;
        font-weight: 300;
        max-width: 660px;
    }
}

@media screen and (max-width:640px) {
    .project-data h1 {
        font-size: 21px;
    }
    .project-data p {
        color: #555555;
        font-size: 24px;
        font-weight: 300;
        max-width: 550px;
    }
}
@media screen and (max-width:600px) {
    .project-data h1 {
        font-size: 21px;
    }
    .project-data p {
        color: #555555;
        font-size: 24px;
        font-weight: 300;
        max-width: 550px;
    }
}

@media screen and (max-width:500px) {
    .project-container {
        width: 100%;
    }

    .project-data h1 {
        font-size: 19px;
    }

    .project-data p {
        color: #555555;
        font-size: 16px;
        max-width: 378px;
        line-height: 1.7;

    }
}

@media screen and (max-width:425px) {
    .project button{
        width: 154px;
        padding: 0;
        height: 54px;
        margin-top: 30px;   
    }
    .project{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .project-container {
        width: 100%;
    }
    .project-data h1 {
        font-size: 19px;
        font-weight: 800;
    }
    .project-data p {
        color: #555555;
        font-size: 16px;
        max-width: 360px;

    }
}

@media screen and (max-width:400px) {
    .project-container {
        width: 100%;
        height: 600px;

    }

    .project-data h1 {
        font-size: 14px;
    }

    .project-data p {
        color: #555555;
        font-size: 17px;
        max-width: 360px;

    }
}

@media screen and (max-width:375px) {
    .project-container {
        width: 100%;
        height: auto;

    }

    .project-data h1 {
        font-size: 13px;
    }

    .project-data p {
        color: #555555;
        font-size: 15px;
        line-height: 1.7;
        max-width: 300px;

    }
}

@media screen and (max-width:320px) {
    .project-data h1 {
        font-size: 16px;
    }

    .project-data p {
        color: #555555;
        font-size: 14px;
        line-height: 1.7;
        max-width: 267px;

    }
}

@media screen and (max-width:300px) {
    .project-data h1 {
        font-size: 13px;
    }

    .project-data p {
        color: #555555;
        font-size: 15px;

    }
}