/* General Navigation Styles */
.nav {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  transition: 0.3s ease-in;
  overflow: hidden;
}

.nav-bg {
  background-color: #313240;
}

.logo img {
  max-width: 200px;
  max-height: 100px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0;
  margin-inline: 60px;
  color: #fff;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.nav-bg .navbar {
  padding: 20px 0;
}

.logo:hover {
  color: #00bcd4;
}

.menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
  z-index: 1100;
}

.menu-icon .bar {
  width: 30px;
  height: 4px;
  background-color: #fff;
  margin: 4px 0;
  border-radius: 2px;
  transition: 0.4s;
}

.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin-left: 25px;
}

.nav-links a {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  transition: color 0.3s, transform 0.3s;
}

.nav-links a:hover {
  color: #00bcd4;
  transform: scale(1.1);
}

.active {
  border: 2px solid #00bcd4;
  padding: 5px;
}

/* Responsive Styles */
@media screen and  (min-width:1200px) {
  .navbar{
     max-width: 1200px;
    margin-inline: auto;
  }  
}
@media screen and (max-width: 1024px) {
  .navbar {
    flex-direction: column;
  }

  .logo img {
    padding-block: 10px;
    height: 40px;
  }

  .nav-bg .logo img {
    display: none;
  }

  .nav-links {
    
    padding-top: 20px;
  }
}

@media screen and (max-width: 980px) {
  .navbar {
    flex-direction: row;
    align-items: start;
    padding: 0;
    margin-inline: 30px;
  }

  .menu-icon {
    display: flex;
    padding-block: 10px;
  }

  .nav-links {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(51, 51, 51, 0.89);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateX(100%);
    transition: transform 0.5s ease-in-out;
  }

  .nav-links.open {
    transform: translateX(0);
  }

  .nav-links li {
    margin: 20px 0;
  }

  .menu-icon.open .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-6px, 6px);
  }

  .menu-icon.open .bar:nth-child(2) {
    opacity: 0;
  }

  .menu-icon.open .bar:nth-child(3) {
    transform: rotate(45deg) translate(-6px, -6px);
  }

  .navbar .logo {
    z-index: 1100;
  }
  .nav-bg .logo img {
    display: inline;
  }
  .nav-bg .navbar {
    padding:0;
}
}

@media screen and (max-width: 768px) {
  .navbar {
    flex-direction: row;
    padding: 0px 20px;
    background-color: #313240;
    margin: 0;
  }

  .nav-bg .navbar {
    height: auto;
    padding-inline: 20px;
  }
}

@media screen and (max-width: 320px) {
  .navbar {
    flex-direction: row;
    padding: 0 20px;
    align-items: start;
  }
}
