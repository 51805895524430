.form-content{
  background-color: #f4f4f4;
}
 .form-header h1 {
  text-align: center;
  line-height: 53px;
  font-size: 36px;
  font-weight: 800;
  text-transform: uppercase;
  margin: 1.2em 0;
  letter-spacing: 0.05em;
  color: #000000;
  padding-top: 40px;
}

.form-header p {
  text-align: center;
  max-width: 692px;
  line-height: 1.5;
  margin: 0 auto;
  color: #838282;
  padding-bottom: 90px;
}

.from-data {
  text-align: center;
}

.from-data form input {
  width: 22%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #dfe5e8;
  border-color: #b7b7b7;
  background-color: transparent;
  margin-bottom: 0.8rem;
  margin: 20px 20px;
  padding: 0.7rem;
  outline: none;
}
.from-data form select {
  width: 24%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #dfe5e8;
  border-color: #b7b7b7;
  background-color: transparent;
  margin-bottom: 0.8rem;
  margin: 20px 20px;
  padding: 0.7rem;
  outline: none;
}
.from-data form select option{
   color: #838282;
}
.from-data form textarea {
  width: 50%;
  height: 156px;
  margin-bottom: 0.8rem;
  border: 1px solid #dfe5e8;
  border-color: #b7b7b7;
  background-color: transparent;
  border-radius: 5px;
  padding: 0.7rem;
  margin-top: 40px;
}

.from-data form textarea {
  resize: none;
}

.from-data button {
  height: 50px;
  line-height: 30px;
  padding: 10px 40px;
  font-size: 20px;
  border-radius: 50px;
  border: none;
  background-color: rgb(72, 196, 212);
  color: rgb(255, 255, 255);
  margin-left: 0;
  margin-top: 60px;
  text-decoration: none;
  font-weight: 700;
  display: inline-block;
  text-transform: uppercase;
  max-width: 159px;
  box-shadow: 0 12px 40px rgba(72, 196, 212, 0.3);
}
.from-data button:hover {
  background-color: #20232f;
  transition: 2s;
}

/* media quary */
@media screen and (min-width: 1200px) {
  .form-content {
    max-width: 1200px;
    margin-inline: auto;
  }
}
@media screen and (max-width: 1024px) {
  .from-data form input {
    width: 42%;
  }
  .from-data form select {
    width: 44%;
  }

  .from-data form textarea {
    width: 90%;
  }
}

.from-data button {
  text-align: center;

}

@media screen and (max-width: 990px) {
  .from-data form input {
    width: 80%;
  }
  .from-data form select {
    width: 82%;
  }

  .from-data form textarea {
    width: 80%;
  }

}

@media screen and (max-width:768px) {
  .form-header h1 {
    font-size: 27px;
  }

  .form-header p {
    font-size: 16px;
  }
}

@media screen and (max-width:425px) {
  .form-header h1 {
    font-size: 19px;
    color: #000000;
    font-weight: 800;
    margin-top: 0;
  }
  .form-header p {
    text-align: start;
    padding-left: 30px;
    padding-right: 30px;

  }

}
@media screen and (max-width:375px){
  .form-header h1 {
    font-size: 15px;
    color: #000000;
    font-weight: 800;
  }
}
@media screen and (max-width:360px) {
  .form-header h1 {
    font-size: 14px;
    color: #000000;
    font-weight: 800;
  }

}