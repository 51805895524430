/* Base styles */
.weare-container {
  margin: 0;
  padding: 0;
  background-image: url("../../Assests/Img/whowearebg.webp");
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: auto;
}


.weare-data h1 {
  padding-top: 90px;
  font-size: 36px;
  padding-bottom: 20px;
  margin: 0;
  font-weight: 800;
}

.weare-data .hr {
  width: 10%;
  background-color: #00bcd4;
  margin-left: 20px;
}

.contactNow {
  text-decoration: none;
}

.contactNow {

  color: #555;
  display: inline-block;

  /* margin: 20px 0; */
  text-align: center;
  font-weight: 400;
  border: 1px solid #808080;
  width: 26%;
  padding: 10px;
  border-radius: 49px;
}

.contactNow:hover {
  color: #fff;
  background-color: #555;

}

.weare-data h2 {
  color: #20232f;
  font-size: 24px;
  font-weight: 200;
  padding-top: 40px;
  line-height: 1.2;
}

.weare-data p {
  color: #555;
  font-size: 18px;
  padding-top: 20px;
  line-height: 1.5;
}

/* Media Queries */
@media screen and (max-width:2560px) {
  .weare-container {
    background-image: url("../../Assests/Img/whowearebg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
  }
  
}
@media screen and (max-width:1440px) {
  .weare-container {
    background-image: url("../../Assests/Img/whowearebg.webp");
    background-repeat: no-repeat;
    background-size: inherit;
    height: auto;
  }
  
}
@media screen and (min-width: 1200px) {
  .weare-content {
    max-width: 1200px;
    margin-inline: auto;
  }

  .weare-data p {
    max-width: 750px;
  }
}

@media screen and (max-width: 1024px) {

  .weare-data h1,
  .weare-data h2,
  .weare-data p {
    padding-left: 20px;
    max-width: 620px;
  }

  .contactNow {
    width: 30%;
    margin-left: 20px;
  }

}

@media screen and (max-width: 950px) {
  .weare-data h1 {
    font-size: 34px;
  }
}

@media screen and (max-width: 800px) {
  .weare-container {
    background-size: cover;
  }

  .weare-data h1 {
    font-size: 28px;
    padding-top: 0;
  }

  .weare-data h2 {
    font-size: 24px;
    line-height: 1.5;
  }


}

@media screen and (max-width: 768px) {
  .contactNow {

    color: #555;
    display: inline-block;
    width: 80% !important;
    /* margin: 20px 0; */
    text-align: center;
    font-weight: 400;
    border: 1px solid #808080;
   
    padding: 10px;
    border-radius: 49px;
  }
  .weare-container {
    background-image: none;
    height: auto;
  }
   .weare-data h1{
    padding-top: 30px;
   }
   .weare-data h2{
    padding-top: 20px;
   }
  .weare-data p {
    max-width: 750px;
  }
}

@media screen and (max-width: 600px) {
  .weare-data h1 {
    font-size: 21px;
    padding-top: 0;
  }
  .weare-data h2{
    padding-top: 0;
  }
}

@media screen and (max-width: 500px) {
.weare-data h1 {
    font-size: 18px;
    padding-top: 0;
  }
  .contactNow{
    width: 50%;
  }

  .weare-data h2 {
    font-size: 16px;
  }
}

 

@media screen and (max-width: 425px) {

  .contactNow{
    width: 60%;
  }
  .weare-data h1 {
    font-size: 14px;
    padding-top: 0;
    margin-top: -45px;
  }
}

@media screen and (max-width: 420px) {

  .contactNow{
    width: 60%;
  }
  .weare-data h1 {
    font-size: 14px;
    margin-top: -40px;
  }
}
@media screen and (max-width: 419px) {
  .weare-data h1{
    margin-top: -100px;
  }
}
@media screen and (max-width: 400px) {
  .weare-data h1 {
    font-size: 14px;
    margin-top: -110px;
  }
}
@media screen and (max-width: 389px) {
  .weare-data h1 {
    font-size: 14px;
    margin-top: -120px;
  }
}

@media screen and (max-width: 375px) {
  .contactNow{
    width: 70%;
  } 
  
  .weare-data h2 {
    padding-right: 30px;
  }

  .weare-data p {
    font-size: 14px;
    padding-right: 20px;
  }
  .weare-data h1 {
    font-size: 14px;
    margin-top: -30%;
    padding-top: 0;
  }

}

@media screen and (max-width: 320px) {
  .weare-data h1 {
    font-size: 16px;
    margin-top: -40%;
  }

  .weare-data h2 {
    font-size: 14px;
    padding-top: 30px;
  }

  .weare-data p {
    font-size: 14px;
  }
}