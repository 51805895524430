.footer-container {
  background-image: url(../../Assests/Img/8.webp);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: right top;
  background-attachment: scroll;
  padding: 15% 90px 90px 90px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
}
.footer-img img {
  max-width: 164px;
}
.footer-paragraph {
  max-width: 564px;
}
.footer-paragraph p {
  color: #ffffff;
  max-width: 480px;
  display: block;
  line-height: 1.6;
}
.footer-info {
  max-width: 303px;
  color: #ffffff;
}
.footer-info p {
  max-width: 261px;
}

/* Media Quary */
@media screen and (min-width: 1200px) {
  .footer-data {
    max-width: 1200px;
    margin-inline: auto;
  }
}
@media screen and (max-width: 1024px) {
  .footer-container {
    padding: 20% 20px 90px 20px;
  }
}

@media screen and (max-width: 768px) {
  .footer-contact {
    display: flex;
    flex-direction: column;
    padding-top: 30%;
    gap: 0;
    align-items: center;
  }
  .contact {
    max-width: 725px;
    text-align: center;
    padding-bottom: 90px;
  }
  .contact h1 {
    padding-bottom: 20px;
  }
  .contact p {
    padding-bottom: 30px;
  }

  .footer-paragraph p {
    padding-bottom: 30px;
  }
  .footer-info {
    padding-bottom: 30px;
  }

  .footer-content {
    display: contents;
  }
}

@media screen and (max-width: 550px) {
  .footer-img img {
    padding-top: 20%;
  }
}
@media screen and (max-width: 360px) {
  .footer-img img {
    padding-top: 30%;
  }
}
@media screen and (max-width: 425px) {
  .footer-container{
    margin-top: -120px;
  }
  .email-data{
    border-radius:0;
    box-shadow: none;
  }
}
