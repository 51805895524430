.business-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 90px;
}

.business-content .h1 {
  background-color:rgb(49 50 65) ;
  width: 100%;
  padding-left: 90px;
  padding-block: 25px;
  line-height: 38px;
  color: #fff;
  font-size: 24px;
  font-weight: 800;
  text-transform: uppercase;
  margin: 1em 0;
  letter-spacing: 0.05em;
  margin-bottom: 30px;
}

 
.business-img img {
  width: 85%;
  height: auto;
  padding-top: 23px;
}

/* media quary */
@media screen and (min-width: 1200px) {
  .business-content {
    max-width: 1200px;
    margin-inline: auto;
  }

}

@media screen and (max-width: 1024px) {
  .business-content {
    display: contents;
    width: 100%;
  }

  .business-content .h1 {
    padding-left: 20px;
    width: 98%;
  }

  .business-content p {
    padding-left: 20px;
  }

  .business-img img {
    width: 98%;
    padding-left: 0;
  }
}

@media screen and (max-width: 912px) {
  .business-content .h1 {
    padding-left: 20px;
    width: 96%;
    font-size: 19px;
  }
}

@media screen and (max-width: 820px) {
  .business-content .h1 {
    padding-left: 20px;
    width: 96%;
    font-size: 19px;
  }
}

@media screen and (max-width: 800px) {
  .business-content .h1 {
    padding-left: 20px;
    width: 97%;
    font-size: 19px;
  }
}

@media screen and (max-width: 768px) {
  .business-content .h1 {
    padding-left: 20px;
    width: 97%;
     
  }

  .weare-data .hr {
    padding-left: 20px;
  }
  .business-content p {
    /* padding-top: 20px; */
    color: #555;
    max-width: 595px;
    display: block;
    padding-right: 18px;
    line-height: 1.7;
    font-size: 16px;
    /* font-weight: 300; */
}
}

@media screen and (max-width: 640px) {
  .business-content .h1 {
    padding-left: 20px;
    width: 96.9%;
     
  }
}

@media screen and (max-width: 600px) {
  .business-content .h1 {
    padding-left: 20px;
    width: 96.5%;
    
  }
}

@media screen and (max-width: 500px) {
  .business-content .h1 {
    padding-left: 20px;
    width: 95%;
    
  }
}

@media screen and (max-width: 490px) {
  .business-content .h1 {
    padding-left: 20px;
    width: 94%; 
  }
}

@media screen and (max-width: 425px) {
  .business-content .h1 {
    padding-left: 20px;
    width: 95%;
    font-size: 19px;
    text-align: center;
    color: #000000;
    font-weight: 800;
    margin-top: 0;
  }

  

  .business-img img {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  .business-content .h1 {
    padding-left: 20px;
    width: 93%;
    font-size: 15px;
  }

 

  .business-img img {
    width: 92%;
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 375px) {
  .business-content .h1 {
    padding-left: 20px;
    width: 94%;
    font-size: 15px;
  }

  

  .business-img img {
    width: 95%;
    padding-left: 0;
  }
}


@media screen and (max-width: 321px) {
  .business-content .h1 {
    padding-left: 20px;
    width: 93.5%;
    font-size: 15px;
  }
}

@media screen and (max-width: 320px) {
  .business-content .h1 {
    padding-left: 20px;
    width: 93%;
    font-size: 16px;
  }

 

  .business-img img {
    width: 95%;
    padding-left: 0;
  }
}