.team-container {
  background-image: url(../../Assests/Img/6.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  background-attachment: scroll;
  height: auto;
  padding: 5% 90px 0 90px;
}

.team-content h1 {
  color: #555;
  line-height: 53px;
  font-size: 36px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  padding-top: 10px;

}

.business-content p {
 
  color: #555;
  max-width: 604px;
  display: block;
  line-height: 1.7;
  font-size: 18px;
  font-weight: 300;
}

.team-row {
  display: flex;
  justify-content: space-between;
}

.team-row>div {
  flex-basis: 32%;
}

.facilities-col {
  height: 100%;
  border-radius: 10px;
  margin-bottom: 15%;
  background-color: white;
  margin-left: 5px;
}

.facilities-col img {
  width: 100%;
  border-radius: 10px;
}

.facilities-col p {
  padding: 30px 30px 30px 30px;
  font-size: 16px;
  text-align: center;
}

.facilities-col h2 {
  margin-top: 16px;
  font-size: 19px;
  font-weight: 800;
  text-align: center;
  color: #555;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.facilities-col h3 {
  color: #555;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  padding-bottom: 30px;
}

.team-data h1 {
  color: #555;
  font-size: 24px;
  font-weight: 800;
  padding-bottom: 20px;
  padding-top: 10px;
}

.team-data p {
  color: #555;
  max-width: 699px;
  display: block;
  line-height: 1.7;
  font-size: 18px;
  font-weight: 300;
  padding-bottom: 60px;
}

/* media quary */
@media screen and (min-width:2560px) {
  .team-container {
    padding: 5% 90px 0 90px;
  }
}

@media screen and (min-width:1360px) {
  .team-CONTENT {
    max-width: 1360px;
    margin-inline: auto;
  }
}

@media screen and (max-width:1024px) {
  .team-container {
    padding-left: 20px;
    padding-right: 20px;
    
  }

  .team-row {
    flex-wrap: wrap;
  }

  .team-row>div {
    flex-basis: 48%;
  }

  .team-row .cards3 {
    margin-top: 35px;
  }

}

@media screen and (max-width:768px) {
  .team-container {  background-image: none;}
  .team-content h1 {
    padding-top: 10%;
  }
}

@media screen and (max-width:767px) {
  .team-row {
    display: contents;
    align-items: center;
  }

  .team-content h1 {
     
    font-size: 27px;
  }

  .team-data h1 {
    
 
    padding-bottom: 20px;
    
  }

  .team-data p {
    line-height: 1.7;
    font-size: 18px;
    max-width: 699px;
    padding-bottom: 90px;

  }

}

@media screen and (max-width: 500px) {
  .team-content h1 {
 
    font-size: 18px;
  }

  .team-content p {
    font-size: 20px;
  }
}

@media screen and (max-width: 425px) {
  
  .team-content h1 {
   
    font-size: 19px;
    line-height: normal;
    font-weight: 800;
  }

  .team-content p {
    font-size: 15px;
  }

  .team-data h1 {
     
    font-size: 19px;
    padding-top: 30px;
    padding-bottom: 20px;
    font-weight: 800;
  }

  
}

@media screen and (max-width: 360px) {
  .team-content h1 {
   
    font-size: 19px;
    line-height: normal;
  }

  .team-content p {
    font-size: 16px;
  }

  .team-data h1 {
     
    font-size: 14px;
    padding-bottom: 20px;

  }

  

}

@media screen and (max-width: 320px) {
  .team-content h1 {
    font-size: 12px;
    line-height: normal;
  }

  .team-content p {
    font-size: 13px;
  }

  .team-data h1 {
    
    font-size: 16px;
    
    padding-bottom: 20px;

  }

 
  .facilities-col h2 {
    font-size: 16px;
  }
  .facilities-col h3{
    font-size: 14px;
  }
}