:root {
  --hover-color: #12f7ff;
}

.services-container {
  background-color: #313241;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: 10px;
}

.services-text {
  padding-top: 60px;
  padding-left: 35%;
  margin: 0;
}

.services-text h2 {
  color: white;
  font-size: 36px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  max-width: 564px;
}

.services-text p {
  color: #ffffff;
  line-height: 25px;
  max-width: 575px;
  font-size: 16px;
  font-weight: normal;
  padding-bottom: 60px;
}

.section-services {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.services-box {
  max-width: 390px;
  text-align: center;
}

.services-icon {
  border: 2px solid #ffffff;
  padding: 2rem;
  font-size: 3rem;
  border-radius: 50%;
  color: white;
}

.services-box h3 {
  margin-top: 20px;
  font-size: 1.5rem;
  color: white;
}

.services-box p {
  padding: 0 30px 60px 30px;
  font-weight: 400;
  letter-spacing: 1px;
  color: white;
  line-height: 1.6;
}

/* Media Queries */
@media screen and (min-width: 1400px) {
  .services-content {
    max-width: 1360px;
    margin-inline: auto;
  }
}

@media screen and (max-width: 1024px) {
  .services-container {
    background: #313241;
  }

  .services-text {
    padding-left: 20px;
  }

  .services-text p {
    max-width: 950px;
  }
}

@media screen and (max-width: 900px) {
  .section-services {
    display: flex;
    flex-direction: column;
  }

  .services-box {
    max-width: 100%;
  }

   
}

@media screen and (max-width: 425px) {
  .services-text h2 {
    font-size: 28px;
  }

  .services-box p {
    font-size: 16px;
    padding-top: 20px;
  }
}

@media screen and (max-width: 360px) {
  .services-text h2 {
    font-size: 26px;
  }

  .services-box h3 {
    font-size: 22px;
  }

  .services-box p {
    font-size: 14px;
  }
}
