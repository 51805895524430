.slider-container {
  height: auto;
  background-image: url(../../Assests/Img/Testimonials.webp);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  padding-inline: 180px;
  padding-block: 90px;

}
.slider h3 {
  text-align: center;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.slider h4 {
  text-align: center;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  color: #ffffff;
}

.slider p {
  padding: 0 60px;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: 300;
}


.slider-img {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-img img {
  border-radius: 50%;
}

.swiper-button-prev,
.swiper-button-next {
  color: #ffffff;
}

.swiper-pagination-bullet-active {
  background-color: rgb(231, 255, 137);

}
.slider-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;  
}

button {
  font-size: 17px;
  font-weight: 700;
  color: rgb(32, 35, 47);
  font-family: Montserrat;
  background-color:#ebc170f5;
  border-radius: 9px;
  outline: none;
  box-sizing: border-box;
  width: 260px;
  height: 66px;
  cursor: pointer;
  visibility: inherit;
  transition: none 0s ease 0s;
  text-align: left;
  margin: 0px;
  padding: 10px 10px 10px 10px;
  border-width: 0px;
  text-align: center;
}

button:hover {
  background-color: rgb(255, 255, 255);
  transition: 1s ease-in-out;
}
.swiper-button-next:after,
.swiper-button-prev:after {
   text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  margin-top: -90px;
}
/* media quary */


@media screen and (min-width:1200px) {
  .slider-content {
    max-width: 1200px;
    margin-inline: auto;
  }

}
@media screen and (max-width:768px) {
  .slider-container{
    padding-inline: 90px;
  }
  .slider h3{
    margin-block: 10px;
  }
  .slider h4{
    margin-block: 5px;
  }
  .slider p {
    padding: 0 30px 30px 30px;
    margin-inline: 10px;
    font-size: 18px;
  }
  .slider-btn{
    padding-top: 0px;
  }
  .slider-btn button{
    height: 60px;
    width: 160px;
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
     text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
    font-size: xx-large;
    margin-top: -60px;
  }
}
@media screen and (max-width:600px) {
  .swiper-button-next:after, .swiper-button-prev:after
  {
    text-transform: none !important;
   letter-spacing: 0;
   font-variant: initial;
   line-height: 1;
   font-size: x-large;
   margin-top: -30px;
 }
  .slider-container{
    padding-inline: 20px;
  }
  .slider p {
    font-size: 18px;
  }
  .slider-btn button{
    border-radius: 9px;
    height: 54px;
    width: 154px;
   }
}
@media screen and (max-width:450px) {
  .slider p {
    font-size: 18px;
  }

}