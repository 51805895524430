.top-btn{
    font-size: 24px;
    font-weight: normal;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 2px;
    position: fixed;
    bottom: 5rem;
    right: 2rem;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #06060f;
    color: #99a9b5;
    opacity: 0.5;
    visibility: visible;
}
 