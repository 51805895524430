.main {
  background-color: #181828;
  width: 100%;
}

.date {
  display: flex;
  color: #ccc;
  height: auto;
}

.date h1 {
  width: 50%;
  padding: 10px;
  font-size: medium;
  border-radius: 5px;
  font-weight: 200; 
  margin-inline: 5px;
 
}
 

/* Media quarys */

@media screen and (min-width: 1200px) {
  .date {
    max-width: 1200px;
    margin-inline: auto;
  }
}
