/* General container styles */
.email-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
}

.email-data {
  background-color: #fff;
  padding: 40px 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 60px;
  max-width: 500px;
  width: 100%;
}

/* Form input styles */
input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
  outline: none;
}

input[type="text"].error,
input[type="email"].error,
textarea.error {
  border-color: red;
}

/* Error message styles */
.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 15px;
}

/* Success message styles */
.success-message {
  background-color: #dff0d8;
  color: #3c763d;
  border: 1px solid #d6e9c6;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  text-align: center;
}

/* reCAPTCHA styles */
.recap {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

/* Button styles */
button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
}

button[type="submit"]:hover:enabled {
  background-color: #0056b3;
}

/* Responsive adjustments */
@media screen and (min-width: 1200px) {
  .email-container {
    max-width: 1200px;
    margin-inline: auto;
  }
}

@media screen and (max-width: 1024px) {
  .textarea textarea {
    width: 100%;
  }
}

@media screen and (max-width: 990px) {
  .name-text input,
  .textarea textarea {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .textarea textarea {
    margin-left: 0;
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .email-data {
    padding: 30px 15px;
  }

  .textarea textarea {
    margin-left: 0;
    width: 100%;
  }
}
@media screen and (max-width: 425px) {
  .Email-btn button{
    width: 154px;
    height: 54px;

  }
}
@media screen and (max-width: 320px) {
  .email-data {
    padding: 20px 10px;
  }
  .email-container{
    padding: 10px;
  }
}
