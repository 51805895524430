.icons-container{
    background-image: url(../../Assests/Img/9.webp);
    background-position: center top;
    background-attachment: scroll;
    width: 100%;
    height: 400px;
    margin-top: -24px;
    padding-block: 60px;
}

.icons-contents{
    display: flex;
    justify-content:  center;
    gap: 60px;
}
.icon{
    width: 100%;
    font-size: 90px;
    color: #e5fd87;
}
.icons-data{
    text-align: center;
}
.icons-data h2{
    font-size: 24px;
    font-weight: 300;
    color: #ffffff;
}

/* meadia quary */

@media screen and (max-width:1024px) {

    .icons-contents {
        flex-wrap: wrap;
    }

    .icons-data{
        text-align: center;
        flex-basis: 45%;
    }
    .img{
        display: none;
    }
}
@media screen and (max-width:767px) {
    .icons-container{
        height: auto;
    }
    .icons-contents{
        display: contents;  
         
    }
    .img img{
        display: none;
    }
   
    
}